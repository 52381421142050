.findBillboard {
  padding-top: 80px;
  padding-bottom: 80px;
  background: url("../../images/uploadsecond.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.findBillboard .findHeader {
  font-size: 24px;
  font-family: commision;
  font-weight: 600;
}
.find {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.searchBtns {
  max-width: 630px;
  width: 100%;
}
.searchBtns form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.searchBtns .findInput {
  position: relative;
  max-width: 300px;
  width: 100%;
  height: 39px;
  margin-right: 10px;
}
.searchBtns .findInput input,
.searchBtns .findInput select {
  max-width: 300px;
  width: 100%;
  height: 39px;
  background: transparent;
  border-radius: 31px;
  border: 1px solid #fff;
  background-color: transparent;
  background-image: url("../../images/inputImag.png");
  background-position: left;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 0px 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: commision;
  outline: 0;
}
.searchBtns .findInput select {
  padding-right: 15px;
}
.searchBtns .findInput input::placeholder,
.searchBtns .findInput select::placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: commision;
}
.searchBtns .findInput span {
  position: absolute;
  right: 20px;
  top: 12px;
  background: url("../../images/search.png");
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.addnewSearch {
  display: flex;
}
.findInput a {
  max-width: 171px;
  width: 100%;
  height: 39px;
  background: linear-gradient(to left, #c060f1, #f046d5);
  border-radius: 31px;
  text-align: center;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #fff;
  text-decoration: none;
  color: #fff;

  font-size: 18px;
  /* font-weight: 600; */
  font-family: commision;
  transition: border 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* select option:not(:checked) {
  background-color: #050206;
  color: #fff;
  font-size: 12px;
} */
select option {
  background-color: #050206;
  color: #fff;
  font-size: 16px;
}
select option:hover {
  background-color: #1f1124;
}
.findInput.searchBtn span {
  display: none;
}

.css-1f02aht-control {
  max-width: 300px;
  width: 100%;
  height: 39px;
  background: transparent;
  border-radius: 31px;
  border: 1px solid #fff;
  background-color: transparent;
  background-image: url("../../images/inputImag.png");
  background-position: left;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 0px 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: commision;
  outline: 0;
}
.css-1dimb5e-singleValue {
  color: #fff !important;
}
.css-1ulgt23-control {
  background-image: url("../../images/inputImag.png") !important;
  background-position: left !important;
  background-size: 100% !important;
  background-repeat: no-repeat;
  padding: 0px 10px;
}
.css-hlgwow {
  height: 38px;
  padding: 0px 3px !important;
}
.css-19bb58m {
  height: 38px;
}
.css-1jqq78o-placeholder {
  color: #fff !important;
  font-size: 16px;
  font-weight: 400;
  font-family: commision;
  outline: 0;
  height: 38px;
  margin-top: -4px;
  line-height: 38px;
}
.css-b62m3t-container {
  margin-left: 5px;
}

.findCards {
  max-width: 300px;
  width: 100%;
  height: 414px;
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer;
}
.findContent {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  max-width: 650px;
  width: 100%;
  overflow-y: scroll;
  height: 850px;
}
.findContent a {
  text-decoration: none;
  color: #fff;
}
.findContent .findCards {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  padding: 20px;
}
.findContent .findCards .findCardsImg {
  max-width: 260px;
  width: 100%;
  height: 200px;
  border-radius: 10px;
}
.findCards .cardTexts .cardFirstText {
  font-size: 16px;
  font-family: commision;
  font-weight: 500;
  padding-top: 10px;
}
.findCards .cardTexts .cardSecondText {
  font-size: 14px;
  font-family: commision;
  font-weight: 400;
  line-height: 21px;
  padding-top: 10px;
  height: 42px;
  margin-bottom: 10px;
}
.cardTexts span {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-family: commision;
  font-weight: 400;
  line-height: 21px;
  padding-top: 10px;
}
.cardTexts span img {
  width: 22px;
  height: 18px;
  margin-right: 10px;
}
.findBillboard .findBtn {
  max-width: 260px;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 20px;
  background: linear-gradient(to left, #c060f1, #f046d5);
  border-radius: 31px;
  text-align: center;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #fff;
  text-decoration: none;
  color: #fff;

  font-size: 18px;
  font-weight: 600;
  font-family: commision;
  transition: border 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.findMap {
  /* background: rgba(0, 0, 0, 0.1); */
  height: 510px;
  max-width: 550px;
  width: 100%;
  margin: 10px;
}
.findMap svg {
  /* max-width: 710px;
  width: 100%;
  min-height: 532px; */
  opacity: 0;
}
.findMap iframe {
  max-width: 710px;
  width: 100%;
  min-height: 532px;
  /* padding: 15px; */
  border: 0;
}

.findModalCards .findCardsImg {
  max-width: 547px;
  width: 100%;
  height: 297px;
  border-radius: 10px;
}
.findModalCards .cardTexts .cardFirstText {
  font-size: 20px;
  font-family: commision;
  font-weight: 500;
  padding-top: 15px;
}
.findModalCards .cardTexts .cardSecondText {
  font-size: 18px;
  font-family: commision;
  font-weight: 400;
  padding-top: 15px;
}
.findModalCards .cardTexts span {
  font-size: 16px;
  font-family: commision;
  font-weight: 400;
  padding-top: 15px;
}
.coleredbtn:hover {
  background: linear-gradient(to right, #d306b2, #9747ff);
}
.coleredbtn:active {
  background: linear-gradient(to right, #ab1693, #2c0b57);
}
.findModalCards .cardTexts span b {
  font-size: 12px;
  font-family: commision;
  font-weight: 400;
  color: #c060f1;
  margin-left: 10px;
}
.modalTable {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 95%;
}
.modalTable p {
  font-size: 14px;
  font-family: commision;
  font-weight: 400;
  line-height: 21px;
}
.findModalCards .findBtn {
  max-width: 549px;
  height: 48px;
  margin-top: 10px;

  background: linear-gradient(to left, #c060f1, #f046d5);
  border-radius: 31px;
  text-align: center;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #fff;
  text-decoration: none;
  color: #fff;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  font-family: commision;
  transition: border 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.findModalCards h2 {
  padding-bottom: 15px;
}
.findModalCards .findBtn:hover {
  background: linear-gradient(to right, #d306b2, #9747ff);
}

.findModalCards .findBtn:active {
  background: linear-gradient(to right, #ab1693, #2c0b57);
}
.findModalCards h2 {
  font-size: 24px;
  font-family: commision;
  font-weight: 600;
}
.findFree {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 5px;
}
.findFree img {
  max-width: 101px;
  height: auto;
  margin-top: 20px;
}
.findFree h3 {
  font-size: 24px;
  font-family: commision;
  margin-top: 15px;
}
.findFree p {
  font-size: 16px;
  font-family: commision;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media (max-width: 1250px) {
  .findCards {
    max-width: 290px;
    margin-right: 10px;
  }
}
@media (max-width: 1210px) {
  .findContent {
    justify-content: center;
  }
  .findCards {
    max-width: 280px;
    margin-right: 5px;
  }
}
@media (max-width: 1004px) {
  .find {
    flex-direction: column-reverse;
    align-items: center;
  }
  .findMap {
    /* height: auto; */
    height: 500px;
  }
  .findContent {
    max-width: 100%;
    height: auto;
    max-height: 840px;
    /* overflow-y: hidden; */
    justify-content: center;
  }
  .addnewSearch {
    flex-direction: column;
  }
  .css-b62m3t-container {
    margin-left: 0px;
  }
}

@media (max-width: 600px) {
  .findContent .findCards {
    max-width: 260px;
    padding: 15px;
  }
}
@media (max-width: 560px) {
  .findContent .findCards {
    max-width: 240px;
    padding: 10px;
  }
  /* .searchBtns .findInput {
    background-size: cover;
    width: 100%;
    height: 10px;
    right: 10px;
    top: 15px;
  } */
  /* .searchBtns form{
    flex-direction: column;
  } */

  .searchBtns .findInput {
    margin-bottom: 10px;
  }

  .findBillboard {
    padding-top: 30px;
  }
  .findBillboard .findHeader {
    font-size: 16px;
    text-align: center;
    margin-bottom: 15px;
  }
  /* .findMap svg {
    min-height: 400px;
  } */
  .findModalCards .findCardsImg {
    height: 220px;
  }
  .findModalCards .cardTexts .cardSecondText {
    font-size: 14px;
  }
  .findModalCards .cardTexts .cardFirstText {
    font-size: 16px;
  }
  .findModalCards h2 {
    padding-top: 10px;
    font-size: 18px;
  }
  .findModalCards .cardTexts span {
    font-size: 12px;
  }
  .cardTexts span {
    line-height: 18px;
  }
  .findModalCards .cardTexts span b {
    font-size: 11px;
  }
  .modalTable p {
    font-size: 12px;
  }
  .css-1jqq78o-placeholder {
    white-space: nowrap;
  }
}
@media (max-width: 520px) {
  .findContent .findCards {
    max-width: 300px;
    padding: 20px;
  }
  .searchBtns .findInput input::placeholder,
  .searchBtns .findInput select option::placeholder {
    font-size: 11px;
  }
  .css-1jqq78o-placeholder {
    font-size: 11px;
    white-space: nowrap;
  }
  .searchBtns .findInput span {
    right: 12px;
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
  }
  .findMap {
  height: 400px;
  }
}
@media (max-width: 380px) {
  .searchBtns form {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .searchBtns .findInput {
    margin-bottom: 10px;
    margin-right: unset;
  }
  .searchBtns .findInput input::placeholder,
  .searchBtns .findInput select option::placeholder {
    font-size: 12px;
  }
  .searchBtns .findInput:nth-child(1) {
    margin-bottom: 15px;
  }
}
