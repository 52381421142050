.uploadContent {
  background: url("../images/uploadBg.png");
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.videoBlock {
  background: url("../images/uploadBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.privicy {
  background: url("../images/upload3.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: 100vh;
}
.privicyContent {
  padding: 55px 15px;
}
.privicyContent h1 {
  font-family: commision;
  font-size: 25px;
  font-weight: 600;
  line-height: 25px;

  margin-bottom: 20px;
}
.privicyContent p {
  font-family: commision;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;

  margin-bottom: 10px;
}
.errorContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 50px;
}
.errorContent img {
  max-width: 674px;
  width: 100%;
}
.errorContent h1 {
  margin-top: 40px;
  font-family: commision;
  font-size: 36px;
  font-weight: 700;
}
.errorContent p {
  margin-top: 20px;
  font-family: commision;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  max-width: 474px;
  text-align: center;
}
.errorContent a {
  width: 275px;
  height: 49px;
  background: linear-gradient(to left, #c060f1, #f046d5);
  border-radius: 31px;
  text-align: center;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #fff;
  text-decoration: none;
  line-height: 49px;
  color: #fff;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  font-family: commision;
  transition: border 0.5s;
  cursor: pointer;
}

.errorContent a:hover {
  background: linear-gradient(to right, #d306b2, #9747ff);
}

.errorContent a:active {
  background: linear-gradient(to right, #ab1693, #2c0b57);
}
.contactUs {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 50px;
}
.talkwithUs {
  max-width: 360px;
}
.talkwithUs h1 {
  font-size: 36px;
  font-weight: 700;
  font-family: commision;
}
.talkwithUs p {
  font-size: 16px;
  font-weight: 400;
  font-family: commision;
  line-height: 26px;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
}
.talkwithUs a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  font-family: commision;
  line-height: 26px;
  margin-top: 10px;
}
.talkwithUs a img {
  margin-right: 8px;
}
.successChange{
  margin-top: 10px;
}
.contactInputs {
  max-width: 448px;
  width: 100%;
  border: 1px solid transparent;
  border-left-color: #6f378b;
  border-top-color: #6f378b;
  border-right-color: #c060f1;
  border-bottom-color: #c060f1;
  border-radius: 10px;
  background: linear-gradient(#1d1028, #67398e);
  display: flex;
  justify-content: center;
}

.contactInfo {
  max-width: 390px;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.contactLabel {
  margin-top: 15px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  font-family: commision;
}
.contactInfo .contactForm {
  display: flex;
  flex-direction: column;
}
.contactInfo .contactForm .nameInput {
  max-width: 388px;
  width: 100%;
  height: 48px;
  background: #fff;
  border-radius: 75px;
  border: 1px solid #c060f178;

  padding: 0 10px;
  outline: none;
}
/* .contactInfo .contactForm .nameInput:invalid{
  color: red
} */
.textareaInput {
  max-width: 388px;
  height: 139px;
  width: 100%;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #c060f178;
  resize: none;
  padding: 10px 10px;
  outline: none;
}
.textareaInput::placeholder {
  font-style: normal;
  font-family: commision;
}
.contactSave {
  max-width: 388px;
  width: 100%;
  height: 48px;
  background: linear-gradient(to left, #c060f1, #f046d5);
  border-radius: 31px;
  text-align: center;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #fff;
  text-decoration: none;
  line-height: 49px;
  color: #fff;

  font-size: 18px;
  font-weight: 600;
  font-family: commision;
  transition: border 0.5s;
  cursor: pointer;
}
.contactSave:hover {
  background: linear-gradient(to right, #d306b2, #9747ff);
}

.contactSave:active {
  background: linear-gradient(to right, #ab1693, #2c0b57);
}
@media (max-width: 768px) {
  .contactUs {
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .talkwithUs h1 {
    font-size: 25px;
    font-weight: 600;
  }
  .talkwithUs p {
    font-size: 14px;
    line-height: 22px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .talkwithUs a {
    font-size: 15px;
    font-weight: 600;
  }
  .talkwithUs {
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .errorContent h1 {
    font-size: 25px;
    text-align: center;
  }
  .privicyContent {
    padding: 35px 7px;
  }
  .privicyContent h1 {
    font-size: 20px;
  }
  .privicyContent p {
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
  }
}
