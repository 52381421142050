.banner {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  /* background: url("../../images/bannerbottom.png");
  background-repeat: no-repeat;
  background-position: bottom; */
}
.newBanner {
  width: 100%;
  height: auto;
  background: url("../../images/bannerbottom.png");
  background-repeat: no-repeat;
  background-position: bottom;
}
.bannerBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("../../images/aibg.png");

  background-repeat: no-repeat;
  background-position: right;
  padding-top: 100px;
}
.bannerBottom img {
  margin-bottom: -5px;
  max-width: 538px;
  width: 100%;
}
.bannerBottomText {
  max-width: 640px;
  width: 100%;
  padding-left: 10px;
}
.bannerBottomText h3 {
  font-size: 48px;
  font-weight: 600;
  font-family: commision;
}
.bannerBottomText p {
  margin-top: 30px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  font-family: poppins;
}
.bannerBottomText a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 255px;
  height: 56px;
  border-radius: 31px;
  border-width: 0 3px 3px 0;
  border-color: #fff;
  border-style: solid;
  background: linear-gradient(to left, #c060f1, #f046d5);
  text-decoration: none;
  color: #fff;
  margin-top: 30px;
  font-size: 20px;
  font-family: commision;
}
.bannerBottomText a:hover {
  background: linear-gradient(to right, #d306b2, #9747ff);
}

.bannerBottomText a:active {
  background: linear-gradient(to right, #ab1693, #2c0b57);
  border-width: 0 2px 2px 0;
}
.bannerText h1 .letterO {
  display: inline-block;
  width: 70px;
  height: 35px;
  border: 8px solid #fff;
  border-radius: 8px;
  margin-left: 3px;
  margin-right: 3px;
}
.bannerText {
  max-width: 670px;
  width: 100%;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  padding-left: 150px;
}
@media (max-width: 1800px) {
  .bannerText {
    padding-left: 110px;
  }
}
@media (max-width: 1700px) {
  .bannerText {
    padding-left: 100px;
  }
}
@media (max-width: 1600px) {
  .bannerText {
    padding-left: 60px;
  }
}
@media (max-width: 1500px) {
  .bannerText {
    padding-left: 40px;
  }
}
@media (max-width: 1400px) {
  .bannerText {
    padding-left: 20px;
  }
}
@media (max-width: 1350px) {
  .bannerText {
    padding-left: 0px;
  }
}
.bannerText .playBox {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  height: 61px;
  width: 225px;
  padding: 5px 7px;
  border: 1.67px solid #fff;
  border-radius: 55px;
  background: url("../../images/playBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: all 0.5s;
}

.bannerText .play {
  background-color: #fff;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: play 5s infinite;
}
@keyframes play {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 160px;
  }
  100% {
    margin-left: 0;
  }
}
.bannerText h1 {
  margin-top: 120px;
  font-size: 48px;
  font-family: travelBold;
  letter-spacing: 3px;
  width: 100%;
}
.bannerImage {
  max-width: 890px;
  width: 100%;
  height: 700px;
  padding-left: 30px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  padding-top: 60px;
}
.bannerImage img {
  width: 100%;
}
.bannerImage .bannerI {
  width: 100%;
  /* opacity: 0; */
  display: none;
}
.animationBlock {
  display: flex;

  transform: rotateZ(-22deg);
}

.bgAnim {
  position: relative;
  border: 8px solid #474747;
  padding: 5px;
  height: 150px;
  margin: 10px;
  max-width: 250px;
  width: 226px;
  height: 216px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 4.6px;
  /* background-image: url("https://78.media.tumblr.com/cae86e76225a25b17332dfc9cf8b1121/tumblr_p7n8kqHMuD1uy4lhuo1_540.png"),
    url("https://78.media.tumblr.com/66445d34fe560351d474af69ef3f2fb0/tumblr_p7n908E1Jb1uy4lhuo1_1280.png"),
    url("https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png"),
    url("https://78.media.tumblr.com/5ecb41b654f4e8878f59445b948ede50/tumblr_p7n8on19cV1uy4lhuo1_1280.png"),
    url("https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png"); */
  background-image: url("../../images/the-skyline-of-a-bustling-city-depicted-in-a-striking-3d-style-emerges-prominently-png.webp"),
    url("https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png");
  background-repeat: repeat-x;
  background-position: 0 20%, 0 100%, 0 50%, 0 100%, 0 0;
  background-size: 475px, 600px, 500px 200px, 1000px, 400px 260px;
  animation: 50s para infinite linear;
}
.animationFirstBlock {
  transform: rotateZ(-22deg);
  margin-top: 280px;
}
.animationFirstBlock .bgAnim {
  width: 275px;
  height: 188px;
  margin: 0;
}
@keyframes para {
  100% {
    background-position: -2000px 20%, -800px 95%, 500px 50%, 1000px 100%,
      400px 0;
  }
}

.bannerRight {
  position: absolute;
  right: 0;
  top: 130px;
  animation: rightImg 6s infinite alternate;
  z-index: 10;
}
@keyframes rightImg {
  0% {
    top: 120px;
    width: 90%;
  }
  20% {
    top: 110px;
    width: 95%;
  }
  40% {
    top: 100px;
    width: 98%;
  }
  60% {
    top: 80px;
    width: 100%;
  }
  80% {
    top: 60px;
    width: 95%;
  }
  100% {
    top: 80px;
    width: 90%;
  }
}
/* .bannerText a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 255px;
  height: 56px;
  border-radius: 31px;
  border-width: 0 3px 3px 0;
  border-color: #fff;
  border-style: solid;
  background: linear-gradient(to left, #c060f1, #f046d5);
  text-decoration: none;
  color: #fff;
  margin-top: 30px;
  font-size: 20px;
  font-family: commision;
} */

/* .bannerText a:hover {
  background: linear-gradient(to right, #d306b2, #9747ff);
}

.bannerText a:active {
  background: linear-gradient(to right, #ab1693, #2c0b57);
  border-width: 0 2px 2px 0;
} */

@media (max-width: 992px) {
  .bannerText h1 {
    margin-top: 20px;
    font-size: 35px;
  }
  .bannerText h1 .letterO {
    width: 47px;
    height: 27px;
  }
  .bannerBottomText h3 {
    font-size: 35px;
  }
  .bannerBottomText p {
    margin-top: 20px;
    font-size: 20px;
  }
  .bannerText .playBox {
    width: 113px;
    height: 30px;
  }
  .bannerText .play {
    width: 20px;
    height: 20px;
    border-radius: 28px;
  }
  @keyframes play {
    0% {
      margin-left: 0;
    }
    50% {
      margin-left: 75px;
    }
    100% {
      margin-left: 0;
    }
  }
  .bannerText .play img {
    width: 12px;
    height: 12px;
  }
  .bannerImage {
    padding-top: 10px;
    max-width: 700px;
    height: 600px;
  }
}
@media (max-width: 768px) {
  .banner {
    flex-direction: column;
  }

  .bannerImage {
    padding-top: 68px;
    max-width: 100%;
  }

  .bannerBottom {
    flex-direction: column;
    padding: 30px 10px 0;
    background-position: right;
    background-size: contain;
  }
}

@media (max-width: 576px) {
  .bannerText {
    white-space: inherit;
    padding: 15px 0;
  }
  .bannerText h1 {
    font-size: 30px;
    letter-spacing: unset;
  }
  .bannerText h1 .letterO {
    width: 47px;
    height: 23px;
  }
  .bannerBottomText h3 {
    font-size: 24px;
  }
  .bannerBottomText p {
    font-size: 18px;
    line-height: 22px;
  }
  .bannerBottomText {
    padding-left: 0px;
  }
  .bannerBottomText a {
    width: 220px;
  }
  .bannerImage {
    padding-top: 40px;
    padding-left: 0;
    height: 450px;
  }
  .bgAnim {
    width: 154px;
    height: 147px;
    background-size: 339px, 400px, 500px 200px, 400px 260px;
  }
  .animationFirstBlock {
    margin-top: 180px;
  }
  .animationFirstBlock .bgAnim {
    width: 179px;
    height: 133px;
  }
  @keyframes rightImg {
    from {
      top: 130px;
      width: 90%;
    }
    to {
      top: 280px;
      width: 105%;
    }
  }
}
