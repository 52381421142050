.accordContent {
  margin-top: 80px;
  margin-bottom: 100px;
}
.accordion h3 {
  text-align: left;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 20px;
  font-family: travel;
}
.accordBg {
  width: 100%;
  height: 100%;
  background: url("../../images/accordion.png");
  background-repeat: no-repeat;
}
.accordClose {
  width: 100%;
  height: 100%;
  background: url("../../images/closeAccord.png");
  background-repeat: no-repeat;
}

.accordionBox {
  border: 1px solid #f8f8f80d;
  padding: 15px;
  margin-top: 15px;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.5s;
}
.accordionBox:hover {
  box-shadow: 1px 1px 4px rgba(255, 0, 0, 0.2),
    -1px -1px 5px rgba(0, 255, 0, 0.3), 0 1px 5px rgba(0, 0, 255, 0.3),
    -1px 0 4px rgba(255, 0, 0, 0.5);
}
.accordionContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordionContent .accordionChevron {
  width: 77px;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
}
.accordionContent .accordionChevronUp {
  width: 77px;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
}
.accordionBox .accordionTitle {
  font-family: commision;
  font-size: 28px;
  line-height: 42px;
  font-weight: 500;
}
.accordionBox .accordionSubtitle {
  font-family: poppins;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  margin-top: 10px;
  max-width: 850px;
}
@media (max-width: 700px) {
  .accordion h3 {
    font-size: 30px;

    line-height: 40px;
  }
  .accordionBox .accordionTitle {
    font-size: 24px;
    line-height: 25px;
  }
}
@media (max-width: 576px) {
  .accordion h3 {
    font-size: 24px;
    line-height: 33px;
  }
  .accordionBox .accordionTitle {
    font-size: 20px;
    line-height: 30px;
  }
  .accordionBox .accordionSubtitle {
    font-size: 14px;
    line-height: 21px;
  }
  .accordionContent .accordionChevron {
    width: 43px;
    height: 33px;
    border-radius: 9px;
  }
  .accordionContent .accordionChevron img,
  .accordionContent .accordionChevronUp img {
    width: 19px;
    height: 19px;
  }
  .accordionContent .accordionChevronUp {
    width: 43px;
    height: 33px;
    border-radius: 9px;
  }
  .accordContent {
    margin-top: 50px;
  }
}
