.advantige {
  margin-top: 80px;
  margin-bottom: 80px;
}
.advantige h2 {
  font-family: travelBold;
  font-size: 48px;
  line-height: 67px;
  font-weight: 900;
}
.advantigeContent {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}
.advantigeOne {
  border: 1px solid transparent;
  border-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1)
    rgba(255, 255, 255, 0.3);

  border-radius: 16px;
  max-width: 559px;
  width: 100%;
  height: 315px;
  padding: 15px 20px;
  background: url("../../images/advantige1.png");
  margin-right: 10px;
  margin-bottom: 30px;
  background-repeat: no-repeat;
}
.advantigeTwo {
  border: 1px solid transparent;
  border-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1)
    rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  max-width: 680px;
  width: 100%;
  height: 315px;
  padding: 15px 20px;
  background: url("../../images/advantige2.png");
  background-repeat: no-repeat;
  margin-bottom: 30px;
  margin-left: 15px;
}
.advantigeThree {
  border: 1px solid transparent;
  border-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1)
    rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  max-width: 634px;
  width: 100%;
  height: 355px;
  padding: 15px 20px;
  background: url("../../images/advantige3.png");
  background-repeat: no-repeat;
  margin-bottom: 30px;
  margin-right: 15px;
}
.advantigeFour {
  border: 1px solid transparent;
  border-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1)
    rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  max-width: 605px;
  width: 100%;
  height: 355px;
  padding: 15px 20px;
  background: url("../../images/advantige4.png");
  background-repeat: no-repeat;
  margin-bottom: 30px;
  margin-left: 15px;
}
.advantigeContent h4 {
  font-size: 28px;
  margin-top: 10px;
  font-family: commision;
  line-height: 42px;
  font-weight: 700;
}
.advantigeContent p {
  font-size: 16px;
  margin-top: 10px;
  font-family: commision;
  line-height: 24px;
  font-weight: 500;
}
.advantigeFlex {
  display: flex;
  width: 100%;
}
@media (max-width: 700px) {
  .advantige h2 {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }
  .advantigeContent h4 {
    font-size: 24px;
    line-height: 33px;
  }
}
@media (max-width: 576px) {
  .advantige h2 {
    font-size: 24px;
    line-height: 36px;
  }
  .advantige {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .advantigeFlex {
    flex-direction: column;
  }
  .advantigeTwo,
  .advantigeFour {
    margin-left: 0;
  }
  .advantigeTwo {
    min-height: 192px;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .advantigeFour,
  .advantigeOne,
  .advantigeThree {
    min-height: 258px;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .advantigeContent h4 {
    font-size: 20px;
    line-height: 30px;
  }
}
