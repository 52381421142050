.loadVideo {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loadVideo h1 {
  text-align: center;
  font-size: 28px;
  font-weight: 800;
  font-family: travel;
}
.videoContent {
  background: url("../../images/upload3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
.loadVideoTab {
  max-width: 460px;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loadVideoTab .checked {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #c060f1;
  line-height: 45px;
  text-align: center;
  position: relative;
  z-index: 5;
}

.loadVideoTab .notchecked {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #c060f1;
  line-height: 45px;
  text-align: center;
  position: relative;
  background: #1d1028;
  z-index: 2;
}
.loadVideoTab .checked.notchecked {
  background: #c060f1;
}
.loadVideoTab .notchecked::before {
  content: "";
  width: 136px;
  height: 1px;
  background: #c060f1;
  position: absolute;
  top: 45%;
  right: 45px;
  z-index: 1;
}
.uploadbuttns {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.loadVideotext {
  margin-top: 20px;
}
.uploadbuttns span {
  font-size: 16px;
  font-weight: 400;
  font-family: commision;
}
.loadVideoContent {
  max-width: 974px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #c060f1;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 25px 20px;
  margin-bottom: 80px;
}
.loadVideoContent ul {
  margin-top: 20px;
  margin-left: 25px;
}
.loadVideoContent ul li {
  font-family: commision;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  margin-top: 5px;
}
.loadVideoContent ul li a {
  font-family: commision;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  color: #fff;
  margin-left: 2px;
  text-decoration: none;
}
.loadVideoContent ul li a:hover {

  text-decoration: underline;
}
.loadVideoContent ul li img{
  width: 20px;
}
.loadVideoContent p {
  font-family: commision;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.loadVideoContent p img {
  margin-right: 10px;
}
.loadVideoContent h3 {
  font-size: 20px;
  font-family: commision;
  font-weight: 500;
  margin-top: 20px;
}
.imgLoad {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 21px;
  height: 126px;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px;
}
.imgLoad form {
  opacity: 0;
  position: relative;
  z-index: 10;
}
.chooseFile {
  position: relative;
}
.chooseFile input {
  position: relative;
  z-index: 10;
  opacity: 0;
}
.videoHide {
  opacity: 0;
}
.choosebtn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.choosebtn button {
  width: 113px;
  height: 32px;

  background: linear-gradient(to left, #c060f1, #f046d5);
  border-radius: 31px;
  text-align: center;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #fff;
  text-decoration: none;
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: commision;
  transition: border 0.5s;
  cursor: pointer;
}
.choosebtn button:hover,
.submitforapprov:hover,
.receiveContent a:hover {
  background: linear-gradient(to right, #d306b2, #9747ff);
}

.choosebtn button:active,
.submitforapprov:active,
.receiveContent a:active {
  background: linear-gradient(to right, #ab1693, #2c0b57);
}

.choosebtn span {
  font-size: 14px;
  font-weight: 500;
  font-family: commision;
}
progress {
  max-width: 827px;
  width: 100%;
  height: 9px;
  background: transparent;
  border: none;
}
progress {
  color: #c060f1;
  border-radius: 8px;
}
progress::-webkit-progress-value {
  background: #c060f1;
  border-radius: 8px;
}

progress::-moz-progress-bar {
  background: #c060f1;
  border-radius: 8px;
}
progress::-webkit-progress-bar {
  background: rgba(255, 255, 255, 0.68);
  border-radius: 8px;
}
.uploadedVideo {
  width: 98px;
  height: 83px;
  background: linear-gradient(#3a274b, #895cb1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.imgClose {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;
  cursor: pointer;
}
.submitforapprov {
  max-width: 358px;
  width: 100%;
  height: 48px;
  background: linear-gradient(to left, #c060f1, #f046d5);
  border-radius: 31px;
  text-align: center;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #fff;
  text-decoration: none;
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: commision;
  transition: border 0.5s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  font-family: commision;
}
.buttomLink {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.receiveContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 754px;
  width: 100%;
  padding: 20px;
  margin-top: 80px;
  margin-bottom: 80px;
  background: rgba(0, 0, 0, 0.32);
  border: 1px solid #c060f1;
  border-radius: 19px;
  border-left-color: #6f378b;
  border-top-color: #6f378b;
}
.receivedVideo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.thanksContent {
  background: url("../../images/upload3.png");
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  height: 100%;
}
.receivedImg {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: linear-gradient(#db50e0, #781e93);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 10px;
}
.approvedImg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 10px;
}
.receiveContent p {
  max-width: 574px;
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  font-family: commision;
  font-weight: 400;
  line-height: 27px;
}
.receiveContent span {
  max-width: 633px;
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  font-family: commision;
  font-weight: 400;
  line-height: 27px;
  display: block;
  padding: 10px;
}
.receiveContent h3 {
  text-align: center;
  margin-top: 20px;
  font-size: 24px;
  font-family: commision;
  font-weight: 500;
  line-height: 27px;

  padding-bottom: 20px;
}
.receiveContent a,
.paymentBlock a {
  max-width: 280px;
  width: 100%;
  height: 48px;
  background: linear-gradient(to left, #c060f1, #f046d5);
  border-radius: 31px;
  text-align: center;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #fff;
  text-decoration: none;
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: commision;
  transition: border 0.5s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  font-family: commision;
  margin-top: 20px;
  margin-bottom: 20px;
}
.forPayBtn {
  max-width: 358px;
}
.paymentBlock {
  max-width: 500px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 20px;
  margin-top: 80px;
  margin-bottom: 80px;
  background: rgba(0, 0, 0, 0.32);
  border: 1px solid #c060f1;
  border-radius: 19px;
  border-left-color: #6f378b;
  border-top-color: #6f378b;
}
.paymentBlock h3 {
  font-size: 22px;
  font-weight: 600;
  font-family: commision;
  margin-top: 20px;
  margin-bottom: 20px;
}
.threeCards {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.cardPayment {
  max-width: 157px;
  width: 100%;
  height: 61px;
  background: #1d1028;
  padding: 10px;
  margin: 10px;
  border: 1px solid #693586;
  border-radius: 4px;
  cursor: pointer;
}
.cardPayment:hover {
  border: 1px solid #df4ede;
}
.cardPayment p {
  font-size: 13px;
  font-weight: 700;
  font-family: commision;
}
@media (max-width: 576px) {
  .loadVideo h1 {
    font-size: 20px;
  }
  .imgLoad {
    padding: 15px 10px;
  }
  .loadVideoContent h3 {
    font-size: 16px;
  }
  .loadVideoTab {
    margin-top: 30px;
    position: relative;
  }
  .loadVideoTab::after {
    content: "";
    width: 75%;
    height: 1px;
    background-color: #c060f1;
    position: absolute;
    top: 25%;
    left: 10%;
  }
  .uploadbuttns span {
    font-size: 14px;
  }
  .loadVideoTab .notchecked::before {
    content: "";
    display: none;
  }
  .receiveContent p {
    font-size: 16px;
    line-height: 24px;
  }
  .receiveContent {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .receivedImg {
    width: 80px;
    height: 80px;
  }
  .receivedImg img {
    width: 35px;
  }
  .approvedImg {
    margin-top: 30px;
  }
  .receiveContent h3 {
    font-size: 20px;
  }
  .receiveContent span {
    font-size: 16px;
    line-height: 24px;
  }
  .paymentBlock h3 {
    font-size: 16px;
  }
  .paymentBlock {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .cardPayment {
    padding: 7px;
  }
}
@media (max-width: 360px) {
  .uploadbuttns span {
    font-size: 13px;
  }
}
