footer {
  position: relative;

  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
}
footer .footerBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 50px;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 80px;
  position: relative;
  z-index: 5;
}
.footer .logo {
  width: 189px;
  height: 54px;
}
.footerLinks {
  width: 60%;
  display: flex;
  justify-content: flex-start;
}
.footerLinks .footerContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.footerLinks .footerContent a,
.footerLinks .footerContent p {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: commision;
  text-decoration: none;
  margin-top: 10px;
}
.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-top: 100px;
}
.copyright p {
  color: #fff;
  font-size: 14px;
  font-family: commision;
  font-weight: 600;
}
@media (max-width: 768px) {
  .footer .logo {
    width: 135px;
    height: 38px;
  }
  .footerLinks {
    width: 70%;
  }
}
@media (max-width: 576px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin-top: 30px;
  }
  .footer .logo {
    margin-bottom: 20px;
  }
  .footerLinks {
    width: 100%;
    justify-content: space-between;
  }
  .copyright {
    margin-top: 40px;
  }
  .footerLinks .footerContent {
    width: unset;
  }

  .footerLinks .footerContent a,
  .footerLinks .footerContent p {
    font-size: 12px;
    line-height: 18px;
  }
  .copyright p {
    font-size: 12px;
  }
  .footerRight {
    padding-right: 40px;
  }
}
