.settingsTab {
  background: url("../../images/upload3.png");
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  height: 100%;
  min-height: 80vh;
  display: flex;
}
.react-tel-input .country-list .country:hover {
  background-color: #1d1028;
}
.tabsBlock {
  margin-top: 80px;
}
.tabsBlock a {
  text-decoration: none;
  cursor: pointer;
}
.tabsGlobal {
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}
.contentTabs {
  /* flex-grow: 1; */
  max-width: 1120px;
  width: 100%;
  height: 600px;

  margin-left: auto;
  margin-right: auto;
  background: rgba(0, 0, 0, 0.32);
  border-width: 0.95px;
  border-style: solid;
  border-left-color: #6f378b;
  border-top-color: #6f378b;
  border-right-color: #c060f1;
  border-bottom-color: #c060f1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
}
.profileContent {
  padding: 20px;
  max-width: 300px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid #c060f1;
}

.profileContent button {
  background: transparent;
  border: 0;
  font-size: 20px;
  color: #fff;
  font-family: commision;
  margin-top: 15px;
  font-weight: 400;
  cursor: pointer;
}
.lastA {
  width: 100%;
}
.profileContent .profile.active {
  font-weight: 700;
  color: #c060f1;
}
.noprofile {
  display: none;
}
.content {
  display: none;
}
.active-content {
  display: block;
  width: 100%;
}
.react-tel-input .country-list .country.highlight {
  background-color: #1d1028;
}
.tabsBlock .tabs {
  background: transparent;

  border: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  font-family: commision;
  text-align: center;
  padding: 10px 40px;
  min-width: 169px;
  cursor: pointer;
}
.tabsBlock .tabs.active {
  color: #c060f1;
  border: 0.95px solid #c060f1;
  border-top-width: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: linear-gradient(#261b2f, #3a264a);
}
.profilnames {
  padding: 20px;
  margin-top: 30px;
}
.profilform {
  display: flex;
  flex-direction: column;
}
.profilform .profilLabel {
  font-size: 16px;
  font-family: commision;
  font-weight: 600;
}
.largInput {
  max-width: 50% !important;
}
.profilform .profilInput,
.nameSurname .profilInput {
  max-width: 388px;
  width: 100%;
  height: 48px;
  border: 1px solid #c060f178;
  border-radius: 75px;
  background: #1d1028;
  margin-top: 10px;
  padding: 10px;
  color: #fff;
  outline: none;
}

.profilform .profilInput::placeholder {
  color: #999999;
  font-size: 16px;
  font-family: commision;
  font-weight: 400;
}
.profilform .profilInput:focus {
  border: 1px solid #e14ddd;
  background: #1d1028;
}
.nameSurname {
  display: flex;
  margin-top: 20px;
}
.nameSurname .prof {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
}
.profilecontact .prof {
  position: relative;
}
.profilecontact .prof img {
  position: absolute;
  right: 20px;
  top: 42px;
  cursor: pointer;
  opacity: 0;
}
.profilecontact .prof:hover img {
  opacity: 1;
}
.cancelSave {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}
.cancelSave .profilSave,
.cancelSave .profilChange {
  width: 157px;
  height: 48px;
  background: linear-gradient(to left, #c060f1, #f046d5);

  border-radius: 31px;
  text-align: center;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #fff;
  text-decoration: none;
  color: #fff;
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
  font-family: commision;
  transition: border 0.5s;
  cursor: pointer;
}
.cancelSave .profilSave:hover,
.cancelSave .profilChange:hover {
  background: linear-gradient(to right, #d306b2, #9747ff);
}
.cancelSave .profilSave:active,
.cancelSave .profilChange:active {
  background: linear-gradient(to right, #ab1693, #2c0b57);
}
.cancelSave .profilChange {
  max-width: 207px;
  width: 100%;
}
.cancelSave .profilCancel {
  width: 157px;
  height: 48px;
  background: transparent;

  border-radius: 31px;
  text-align: center;
  border-width: 1px 3px 3px 1px;
  border-style: solid;
  border-color: #fff;
  text-decoration: none;
  color: #fff;
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
  font-family: commision;
  transition: border 0.5s;
  cursor: pointer;
}
.successChange {
  margin-top: 10px;
}
.profilecontact {
  padding: 20px;
}
.prof .react-tel-input .form-control {
  height: 48px !important;
  max-width: 390px;
  width: 100%;

  color: #fff;
  margin-right: 10px;
}
.prof .react-tel-input {
  margin-top: 10px;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent;
}
.react-tel-input .country-list {
  background-color: #1d1028;
}
input:-internal-autofill-selected {
  background-color: #1d1028 !important;
}
.error {
  margin-top: 5px;
  font-size: 12px;
  color: #ff3032;
}
input[type="email"]:invalid {
  border: 1px solid #ff3032;
}
.closebtn {
  background: transparent;
  border: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.changeEmail {
  padding: 20px;
}
.changeEmail h2 {
  font-family: commision;
  font-size: 24px;
  font-weight: 600;
}
.changeEmail p {
  font-family: commision;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 10px;
}

.changeEmail .prof {
  margin-top: 10px;
}
.changeEmail .profilecontact {
  padding: 10px 0;
}

.changeEmail .cancelSave .profilSave {
  width: 100%;
  margin-right: unset;
}

.verification {
  max-width: 388px;
  width: 100%;
  margin-top: 10px;
}
.profilecontact a {
  font-family: commision;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-top: 10px;
  text-align: center;
  color: #fff;
  display: block;
}
.currentPass {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 50%;
}
.currentPass img {
  position: absolute;
  right: 15px;
  top: 42px;
  cursor: pointer;
  opacity: 0;
}
.currentPass:hover img {
  opacity: 1;
}
.changePasswordTite {
  font-family: commision;
  font-weight: 600;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.profilform .profilLabelPass {
  font-size: 16px;
  font-family: commision;
  font-weight: 500;
}
.announcmentContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.announcment {
  max-width: 1120px;
  width: 100%;
  min-height: 600px;

  margin-left: auto;
  margin-right: auto;
  background: rgba(0, 0, 0, 0.32);
  border-width: 0.95px;
  border-style: solid;
  border-left-color: #6f378b;
  border-top-color: #6f378b;
  border-right-color: #c060f1;
  border-bottom-color: #c060f1;
  border-radius: 10px;
}
.announcment h3 {
  display: flex;
  font-size: 20px;
  font-family: commision;
  font-weight: 600;
  align-items: center;
  padding: 15px;
  margin-top: 20px;
}
.announcment h3 span {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background: linear-gradient(to left, #ed47d6, #c45ced);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.announcmentBox {
  max-width: 530px;
  width: 100%;
  flex: 0 1 47%;
  display: flex;
  align-items: flex-start;
  margin: 15px;

  background-color: #000;
  padding: 15px;
  border-radius: 10px;
}
.announcmentBox .announcmentBoxImage {
  width: 221px;
  height: 238px;
  border-radius: 10px;
}
.announcmentText .announceFirst {
  width: 19px;
  height: 15px;
  margin-right: 5px;
}
.announcmentText .announceSecond {
  width: 21px;
  height: 21px;
  margin-right: 5px;
}
.announcmentText {
  height: 238px;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.announcmentText p {
  font-size: 16px;
  font-family: commision;
  font-weight: 500;
  margin-bottom: 10px;
}
.announceSpan {
  font-size: 14px;
  font-family: commision;
  font-weight: 400;
  margin-bottom: 10px;
  display: block;
}
.announcePrice {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.announcePrice span {
  font-size: 14px;
  font-family: commision;
  font-weight: 400;
}
.announceGreen {
  background: #59ae0b66;
  font-size: 12px;
  font-family: commision;
  font-weight: 400;
  border: 0;
  width: 93px;
  height: 24px;
  border-radius: 50px;
  color: #59ae0b;
}
.announceGray {
  background: #9999998f;
  font-size: 12px;
  font-family: commision;
  font-weight: 400;
  border: 0;
  width: 93px;
  height: 24px;
  border-radius: 50px;
  color: #999999;
}
.announceRed {
  background: #f8a1a18f;
  font-size: 12px;
  font-family: commision;
  font-weight: 400;
  border: 0;
  width: 93px;
  height: 24px;
  border-radius: 50px;
  color: #999999;
}
.notification {
  max-width: 1120px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #0e0e0e;
  border-width: 0.95px;
  border-style: solid;
  border-left-color: #6f378b;
  border-top-color: #6f378b;
  border-right-color: #c060f1;
  border-bottom-color: #c060f1;
  border-radius: 10px;
}
.notificationBlock {
  margin: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
}
.notificationDivider {
  width: 100%;
  height: 1px;
  background: #ffffff12;
}
.notificationImg {
  width: 65px;
  height: 63px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.notificationBlock h3 {
  font-family: commision;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.notificationBlock p {
  color: #ffffffd4;
  font-weight: 600;
  font-size: 14px;
}
.notificationText {
  width: 90%;
}
.notificationModal {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.notificationModal p {
  font-family: commision;
  font-size: 18px;
  font-weight: 400;
  margin-top: 15px;
  line-height: 27px;
}
.notificationModal img {
  width: 63px;
}
@media (max-width: 1050px) {
  .announcmentBox {
    flex: 0 1 46%;
    margin: 15px 8px;
  }
}
@media (max-width: 992px) {
  .nameSurname {
    flex-direction: column;
    margin-top: 0;
  }
  .nameSurname .profilLabel,
  .nameSurname .profilLabelPass {
    margin-top: 10px;
  }
  .cancelSave {
    justify-content: center;
  }
  .profilecontact .prof {
    max-width: 388px;
  }

  .profilecontact .prof img {
    top: 50px;
  }
  .changeEmail .profilecontact .prof img {
    top: 43px;
  }
  .largInput {
    max-width: 388px !important;
    width: 100%;
  }
  .currentPass {
    max-width: 388px;
  }
}
@media (max-width: 940px) {
  .announcmentContent {
    flex-direction: column;
  }
  .announcmentBox {
    margin: 10px;
  }
  .tabsBlock .tabs {
    min-width: unset;
    padding: 10px 20px;
  }
  .profileContent {
    padding: 14px 10px;
  }
  .profileContent button {
    font-size: 15px;
  }
}
@media (max-width: 700px) {
  .profileContent {
    max-width: 210px;
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    max-width: 90% !important;
    padding: 8px !important;
  }
}
@media (max-width: 576px) {
  .tabsBlock {
    margin-top: 30px;
  }
  .announcmentBox .announcmentBoxImage {
    padding-left: 5px;
  }
  .tabsBlock .tabs {
    padding: 10px 5px;
    font-size: 18px;
  }
  .notificationBlock {
    flex-direction: column;
    padding: 5px 0;
  }
  .notificationImg {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .notificationText {
    width: 100%;
  }
  .notificationBlock h3 {
    font-size: 15px;
  }
  .notificationBlock p {
    font-size: 13px;
  }
  .announcment {
    background: none;
    border: none;
  }
  .announcmentBox {
    margin: 10px 0;
    padding: 10px 2px 15px;
  }
  .changePasswordTite {
    font-size: 14px;
    margin-top: 10px;
  }
  .profilform .profilLabelPass {
    font-size: 13px;
  }
  .profileContent {
    align-items: flex-start;
    border-right: 0;
    max-width: 100%;
    height: auto;
    flex-direction: row;
  }
  .profilecontact .prof img {
    top: 47px;
  }
  .profileContent button {
    margin-left: 10px;
  }

  .profilform .profilLabel {
    font-size: 13px;
  }
  .contentTabs {
    flex-direction: column;
  }
  .changeEmail h2 {
    font-size: 16px;
  }
  .changeEmail p {
    font-size: 14px;
  }

  .changeEmail {
    padding: 10px;
  }
  .profilnames {
    margin-top: 20px;
  }
  .profilform .profilInput::placeholder,
  .nameSurname .profilInput::placeholder {
    font-size: 11px;
  }
  .currentPass img {
    top: 36px;
  }
}
@media (max-width: 540px) {
  .announcmentBox {
    flex-direction: column;
  }
  .announcment h3 {
    font-size: 16px;
    padding: 15px 5px;
  }
  .announcment h3 span {
    width: 25px;
    height: 25px;
  }
  .announcmentBox .announcmentBoxImage {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .announcmentText {
    height: auto;
    margin-top: 10px;
  }
  .announceGreen,
  .announceGray,
  .announceRed {
    margin-top: 10px;
  }
  .cancelSave .profilSave,
  .cancelSave .profilChange,
  .cancelSave .profilCancel {
    font-size: 16px;
  }
}
@media (max-width: 380px) {
  .profileContent button {
    font-size: 14px;
  }
  .tabsBlock .tabs {
    font-size: 16px;
  }
}
@media (max-width: 340px) {
  .tabsBlock .tabs {
    font-size: 14px;
  }
}
