body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: commision;
  src: url("../public/fonts/Commissioner/static/Commissioner-Regular.ttf");
}
@font-face {
  font-family: travel;
  src: url("../public/fonts/Travels/TT\ Travels\ Next\ Trial\ Medium.ttf");
}
@font-face {
  font-family: travelBold;
  src: url("../public/fonts/Travels/TT\ Travels\ Next\ Trial\ ExtraBold.ttf");
}
@font-face {
  font-family: poppins;
  src: url("../public/fonts/Poppins/Poppins-Medium.ttf");
}
body,
html {
  width: 100%;
  background-color: #050206;
  color: #fff;
}
.container {
  max-width: 1300px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.bigContainer {
  width: 100%;
  position: relative;
  z-index: 100;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, #c060f1, #6f378b);
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient (to left, #c060f1, #6f378b);
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 1000;
}
