.displayHeading {
  padding-top: 80px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.displayHeading h2 {
  font-family: commision;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  max-width: 767px;
  padding: 10px 15px;
}
.displayHeading h2 span {
  color: #9026b0;
}
.displayIcons {
  padding: 30px 0 100px;
  display: flex;
  justify-content: space-between;
}
.displayContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  flex: 0 1 23%;
  overflow: hidden;
  position: relative;

  height: 280px;
}

.displayContent p {
  text-align: center;
  position: absolute;
  bottom: 15px;
  font-family: commision;
  font-size: 20px;
  font-weight: 700;
}

.billboard {
  background-image: url("../../images/emiratesbg.png");
  background-repeat: no-repeat;
  background-position: 0% 50%;
  width: 100%;

  margin-top: 80px;
  padding-bottom: 80px;
}
.billboardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bgborder {
  max-width: 597px;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.12);
  /* border-image: linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.31))  10 stretch; */
  padding: 20px;
  border-radius: 33.92px;

  margin-bottom: 80px;
  background: linear-gradient(to left, #d9d9d90d 12%, #d9d9d90d 31%);
  position: relative;
}
.billboard h3 {
  text-align: center;
  font-family: travel;
  font-size: 36px;
  padding: 0 15px;
  max-width: 507px;
}

.rectclass.ready {
  transform: matrix(-1, 0, 0, 1, 422, 0);
  animation: matrix 10s forwards infinite;
  opacity: 0;
}
@keyframes matrix {
  0% {
    transform: matrix(-1, 0, 0, 1, 422, 0);
    opacity: 1;
  }
  20% {
    transform: matrix(-1, 0, 0, 1, 422, 76);
    opacity: 1;
  }
  30% {
    transform: matrix(-1, 0, 0, 1, 422, 76);
    opacity: 1;
  }
  40% {
    transform: matrix(-1, 0, 0, 1, 422, 76);
    opacity: 1;
  }
  100% {
    transform: matrix(-1, 0, 0, 1, 422, 76);
    opacity: 1;
  }
}
.svgText {
  animation: matrixText 10s forwards infinite;
  transform: translateY(-80px);
}
.rectclass.readysecond {
  transform: matrix(-1, 0, 0, 1, 416, -10);
  animation: matrix2 10s forwards infinite;
  opacity: 0;
}
@keyframes matrixText {
  0% {
    transform: translateY(-80px);
  }
  20% {
    transform: translateY(0px);
  }
  30% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes matrix2 {
  0% {
    transform: matrix(-1, 0, 0, 1, 416, -10);
    opacity: 1;
  }
  20% {
    transform: matrix(-1, 0, 0, 1, 416, 80);
    opacity: 1;
  }
  30% {
    transform: matrix(-1, 0, 0, 1, 416, 80);
    opacity: 1;
  }
  40% {
    transform: matrix(-1, 0, 0, 1, 416, 80);
    opacity: 1;
  }
  100% {
    transform: matrix(-1, 0, 0, 1, 416, 80);
    opacity: 1;
  }
}
.rectclass.ready2 {
  transform: matrix(-1, 0, 0, 1, 284.537, -150);
  animation: matrixtwo 10s forwards infinite;
  opacity: 0;
  animation-delay: 2s;
}
@keyframes matrixtwo {
  0% {
    transform: matrix(-1, 0, 0, 1, 284.537, 0);
    opacity: 1;
  }
  20% {
    transform: matrix(-1, 0, 0, 1, 284.537, 250);
    opacity: 1;
  }
  30% {
    transform: matrix(-1, 0, 0, 1, 284.537, 250);
    opacity: 1;
  }
  40% {
    transform: matrix(-1 0 0 1 284.537 250);
    opacity: 1;
  }
  100% {
    transform: matrix(-1, 0, 0, 1, 284.537, 250);
    opacity: 1;
  }
}
.svgText2 {
  animation: matrixText2 10s forwards infinite;
  transform: translateY(-300px);
  animation-delay: 2s;
}
.rectclass.readysecond2 {
  transform: matrix(-1, 0, 0, 1, 276, 0);
  animation: matrix3 10s forwards infinite;
  animation-delay: 2s;
}
@keyframes matrixText2 {
  0% {
    transform: translateY(-250px);
  }
  20% {
    transform: translateY(0px);
  }
  30% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes matrix3 {
  0% {
    transform: matrix(-1, 0, 0, 1, 276, 0);
    opacity: 1;
  }
  20% {
    transform: matrix(-1, 0, 0, 1, 276, 254);
    opacity: 1;
  }
  30% {
    transform: matrix(-1, 0, 0, 1, 276, 254);
    opacity: 1;
  }
  40% {
    transform: matrix(-1, 0, 0, 1, 276, 254);
    opacity: 1;
  }
  100% {
    transform: matrix(-1, 0, 0, 1, 276, 254);
    opacity: 1;
  }
}
@media (max-width: 992px) {
  .displayIcons {
    padding: 30px 0;
  }
  .billboardContent {
    flex-direction: column-reverse;
  }
  .billboard {
    margin-top: 30px;
    padding-bottom: 20px;
  }
  .billboard h3 {
    margin-bottom: 20px;
  }
}
@media (max-width: 890px) {
  .displayContent {
    flex: 0 1 48%;
  }
  .displayIcons {
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .displayIcons .displayContent .displayBg .displayBgSecond {
    width: 100%;
    /* top: 34%;
    right: 50%; */
  }
  .bgborder svg {
    max-width: 569px;
    width: 100%;
  }
  .displayContent p {
    bottom: 15px;
  }
}

@media (max-width: 576px) {
  .displayContent {
    width: 136px;
    /* height: 118px; */
    height: 180px;
    margin-left: unset;
  }
  .displayIcons .displayContent .displayBg .displayBgSecond {
    height: auto;
  }
  .displayHeading {
    padding-top: 20px;
  }
  .displayHeading h2 {
    font-size: 20px;
    line-height: 24px;
  }
  .displayContent p {
    font-size: 14px;
    font-weight: 700;
    bottom: 5px;
  }
  .billboard {
    padding-bottom: 0px;
  }
  .billboard h3 {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 2px;
  }
  .bgborder svg {
    height: 395px;
  }
  .bgborder {
    padding: 0;
  }
  .billboardContent.container {
    padding-left: 0;
    padding-right: 0;
  }
  .rectclass.ready2 {
    width: 129px;
    height: 54px;
    transform: matrix(-1, 0, 0, 1, 240.537, 0);
    animation: matrixtwo 10s forwards infinite;
    opacity: 0;
    animation-delay: 2s;
  }
  @keyframes matrixtwo {
    0% {
      transform: matrix(-1, 0, 0, 1, 240.537, 0);
      opacity: 1;
    }
    20% {
      transform: matrix(-1, 0, 0, 1, 240.537, 260);
      opacity: 1;
    }
    30% {
      transform: matrix(-1, 0, 0, 1, 240.537, 260);
      opacity: 1;
    }
    40% {
      transform: matrix(-1 0 0 1 240.537 260);
      opacity: 1;
    }
    100% {
      transform: matrix(-1, 0, 0, 1, 240.537, 260);
      opacity: 1;
    }
  }
  .rectclass.ready {
    width: 129px;
    height: 54px;
    transform: matrix(1, 0, 0, 1, 388, 0);
    animation: matrix 10s forwards infinite;
    opacity: 0;
  }
  @keyframes matrix {
    0% {
      transform: matrix(-1, 0, 0, 1, 388, 0);
      opacity: 1;
    }
    20% {
      transform: matrix(-1, 0, 0, 1, 388, 90);
      opacity: 1;
    }
    30% {
      transform: matrix(-1, 0, 0, 1, 388, 90);
      opacity: 1;
    }
    40% {
      transform: matrix(-1, 0, 0, 1, 388, 90);
      opacity: 1;
    }
    100% {
      transform: matrix(-1, 0, 0, 1, 388, 90);
      opacity: 1;
    }
  }
  .rectclass.readysecond2 {
    width: 55px;
    height: 48px;
    transform: matrix(-1, 0, 0, 1, 235, 0);
    animation: matrix3 10s forwards infinite;
    animation-delay: 2s;
    opacity: 0;
  }
  @keyframes matrix3 {
    0% {
      transform: matrix(-1, 0, 0, 1, 235, 0);
      opacity: 1;
    }
    20% {
      transform: matrix(-1, 0, 0, 1, 235, 264);
      opacity: 1;
    }
    30% {
      transform: matrix(-1, 0, 0, 1, 235, 264);
      opacity: 1;
    }
    40% {
      transform: matrix(-1, 0, 0, 1, 235, 264);
      opacity: 1;
    }
    100% {
      transform: matrix(-1, 0, 0, 1, 235, 264);
      opacity: 1;
    }
  }
  .rectclass.readysecond {
    width: 55px;
    height: 48px;

    transform: matrix(-1, 0, 0, 1, 385, 0);
    animation: matrix2 10s forwards infinite;
    opacity: 0;
  }
  @keyframes matrix2 {
    0% {
      transform: matrix(-1, 0, 0, 1, 385, 0);
      opacity: 1;
    }
    20% {
      transform: matrix(-1, 0, 0, 1, 385, 92);
      opacity: 1;
    }
    30% {
      transform: matrix(-1, 0, 0, 1, 385, 92);
      opacity: 1;
    }
    40% {
      transform: matrix(-1, 0, 0, 1, 385, 92);
      opacity: 1;
    }
    100% {
      transform: matrix(-1, 0, 0, 1, 385, 92);
      opacity: 1;
    }
  }
  .svgText {
    transform: translateX(-90px);
  }
  @keyframes matrixText {
    0% {
      transform: translateY(-90px);
    }
    20% {
      transform: translateY(0px);
    }
    30% {
      transform: translateY(0px);
    }
    40% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(0px);
    }
  }
}
@media (max-width: 450px) {
  .displayContent p {
    bottom: 20px;
  }
}
