.signup {
  padding: 40px 0;

  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url("../../images/signinbg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.react-tel-input .country-list .country:hover {
  background-color: #1d1028;
}
.signUpContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signUpForm {
  padding: 15px;
  border: 0.69px solid rgba(71, 71, 71, 0.8);
  max-width: 469px;
  width: 100%;
  margin-top: 80px;
  border-radius: 3.47px;
  background-color: rgba(0, 0, 0, 0.3);
}
.signUpForm h1 {
  font-family: commision;
  font-size: 24px;
  font-weight: 600;
  padding: 10px 20px;
  margin-top: 20px;
}
.signUpForm .react-tel-input .form-control {
  width: 388px;
}
.formContainer {
  padding: 10px 20px;
}
.formContainer .button-form {
  max-width: 388px;
  width: 100%;
  height: 48px;
  background: linear-gradient(to left, #c060f1, #f046d5);
  border-width: 0 3px 3px 0;
  border-radius: 31px;
  border-style: solid;
  border-color: #fff;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  font-family: commision;
  font-weight: 600;
  margin-top: 25px;
  transition: all 0.5s;
}
.formContainer .button-form:hover {
  background: linear-gradient(to right, #d306b2, #9747ff);
}
.formContainer .button-form:active {
  background: linear-gradient(to right, #ab1693, #2c0b57);
}
.formContainer .input-form {
  display: flex;
  max-width: 388px;
  width: 100%;
  padding: 5px 10px;
  height: 48px;
  background: #1d1028;
  border: 1px solid #c060f178;
  border-radius: 75px;
  color: #fff;
  outline: none;
}
.formContainer .input-form::placeholder {
  color: #999999;
  font-size: 16px;
  font-family: commision;
  font-weight: 500;
}
.error {
  font-size: 12px;
  color: #ff3032;
}
input[type="email"]:invalid {
  border: 1px solid #ff3032;
}
input[type="text"]:invalid {
  border: 1px solid #ff3032;
}
.input-form:focus,
.signUpForm .react-tel-input .form-control:focus {
  border: 1px solid #e14ddd;
  background: #1d1028;
}
.check-form {
  position: relative;
}
.check-form a {
  color: #fff;
  text-decoration: none;
  margin-left: 2px;
  margin-right: 2px;
}
.checkbox-container::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 18px;
  width: 18px;
  background-color: #000;
  z-index: 0;
  border: 1px solid #c060f178;
}
.checkbox-container::after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #e14ddd;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.sizeModal {
  max-width: 350px;
}
/* Display checkmark when checked */
input[type="checkbox"]:checked ~ .checkbox-container::after {
  display: block;
}
.formContainer .label-form {
  margin-top: 15px;
  display: flex;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: commision;
  font-weight: 500;
}
.formlink {
  margin-top: 15px;
  display: flex;
  padding-bottom: 15px;
  font-size: 14px;
  font-family: commision;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
}
.formContainer .check-form {
  margin-top: 15px;
  display: flex;
  font-size: 12px;
  font-family: commision;
  font-weight: 500;
}
.formContainer .check-form input[type="checkbox"] {
  border: 1px solid #c060f1;
  border-radius: 3px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  color: #fff;
  accent-color: #c060f1;
}
/* .formContainer .check-form input[type="checkbox"]:checked {
  background-color: #c060f1;
  filter: purple(100%) brightness(80%) hue-rotate(170deg) saturate(70%)
    contrast(300%);
} */
.formContainer .pass {
  position: relative;
}
.formContainer .pass img {
  position: absolute;
  top: 35px;
  right: 30px;
  cursor: pointer;
}
.formButtom {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  font-size: 12px;
  font-family: commision;
  font-weight: 500;
}
.formButtom a {
  color: #c75bec;
  margin-left: 5px;
  font-size: 12px;
  font-family: commision;
  font-weight: 500;
}
.phonVerify .verifyPhone {
  width: 100%;
}
.react-tel-input .form-control {
  height: 48px !important;
  max-width: 388px;

  color: #fff;
  background: #1d1028 !important;
  border: 1px solid #c060f178 !important;
  border-radius: 75px !important;
}
.react-tel-input .flag-dropdown {
  background: #1d1028 !important;
  border: 1px solid #c060f178 !important;
  border-radius: 75px 0 0 75px !important;
}
.phonVerify {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: flex-end;
}
.phonVerify button {
  height: 48px;
  max-width: 111px;
  width: 100%;
  background: linear-gradient(to left, #c060f1, #f046d5);
  border-radius: 31px;
  border-width: 0 3px 3px 0;
  border-color: #fff;
  border-style: solid;
  font-size: 18px;
  font-family: commision;
  font-weight: 600;
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
}
.phonVerify button:hover {
  background: linear-gradient(to right, #d306b2, #9747ff);
}
.phonVerify button:active {
  background: linear-gradient(to right, #ab1693, #2c0b57);
}
.resetPass {
  font-size: 16px;
  font-weight: 400;
  font-family: commision;
  line-height: 22px;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.5);
  display: block;
}
.linkButtom {
  font-size: 18px;
  font-weight: 600;
  font-family: commisio n;
  line-height: 22px;
  padding: 20px;
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
}
.radiobtn {
  display: flex;
}
.radiobtn .input-radio {
  border: 1px solid #c060f1;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  color: #fff;
  accent-color: #c060f1;
}
.radiobtn label:nth-child(2) {
  margin-left: 10px;
}
@media (max-width: 576px) {
  .singllogo {
    width: 140px;
    height: 40px;
  }
  .phonVerify {
    flex-direction: column;
    align-items: center;
  }
  .signUpForm {
    margin-top: 40px;
  }
  .phonVerify button {
    margin-top: 20px;
  }

  .react-tel-input .form-control {
    max-width: 388px;
    width: 100% !important;
  }
  .react-tel-input {
    max-width: 388px;
    width: 100%;
  }
  .checkbox-container::before {
    content: "";
    left: 0;
    top: 0;
    border: 1px solid #c060f178;
  }
}
@media (max-width: 450px) {
  .formContainer,
  .resetPass {
    padding: 10px 0;
  }

  .formContainer .button-form {
    font-size: 15px;
  }

  .signUpForm h1 {
    font-size: 20px;
    padding: 10px 0;
  }
}
