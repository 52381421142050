.header {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .logo {
  width: 220px;
  height: 63px;
}
button {
  font-family: commision;
}
.headerInputs {
  display: flex;
  justify-content: flex-end;
  width: 65%;
}
.inputElement {
  max-width: 416px;
  width: 100%;
  position: relative;
  margin-right: 10px;
}
.headerInputs input {
  background-color: transparent;
  background-image: url("../../images/inputImag.png");
  background-position: left;
  background-size: 100%;
  background-repeat: no-repeat;
  max-width: 416px;
  width: 100%;
  height: 48px;
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 31px;
  padding: 0px 15px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  font-family: commision;
  outline: 0;
  margin-right: 10px;
}
.headerInputs input::placeholder {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  font-family: commision;
}
.headerInputs .inputElement span img {
  /* content: ""; */
  position: absolute;
  right: 20px;
  top: 15px;
  background: url("../../images/search.png");
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.sineInUp {
  display: flex;
}
.signUp {
  background: linear-gradient(to left, #f046d5, #c060f1);
  width: 197px;
  height: 48px;
  line-height: 48px;
  border-radius: 31px;
  text-align: center;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #fff;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-family: commision;
  transition: border 0.5s;
  margin-right: 10px;
}
.signUp:hover {
  background: linear-gradient(to right, #d306b2, #9747ff);
}

.signUp:active {
  background: linear-gradient(to right, #ab1693, #2c0b57);
  border-width: 0 2px 2px 0;
}

.signIn {
  background: transparent;
  width: 139px;
  height: 48px;
  line-height: 48px;
  border-radius: 31px;
  text-align: center;
  border-width: 1px 3px 3px 1px;
  border-style: solid;
  border-color: #fff;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-family: commision;
  transition: border 0.5s;
}
.signIn:active {
  border-width: 0 2px 2px 0;
}
.menuBar {
  display: none;
}
.menuClose {
  display: none;
}
@media (max-width: 1220px) {
  .headerInputs {
    width: 70%;
  }
  .inputElement {
    max-width: 390px;
    margin-right: 10px;
  }
  .headerInputs input {
    max-width: 390px;
  }
}
@media (max-width: 992px) {
  .headerInputs {
    width: 75%;
  }
  .inputElement {
    max-width: 330px;
  }
  .headerInputs input {
    max-width: 330px;
  }
  .header .logo {
    width: 170px;
    height: auto;
  }
  .signUp {
    width: 160px;
    white-space: nowrap;
    padding: 0px 5px;
  }
}
@media (max-width: 768px) {
  .header {
    padding: 22px 0 5px;
  }
  .header .logo {
    width: 140px;
    height: 40px;
  }
  .setIsMenuOpenmobile {
    display: none;
  }
  .headerInputs {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: #050206;
    background-image: url("../../images/accordion.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 50;
    padding: 50px 30px;
    transition: all 0.5s;
    min-height: 25vh;
    height: auto;
    transform: translateY(-100%);
    animation: animmenu 1s forwards;
  }
  @keyframes animmenu {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
  }

  .sineInUp {
    margin-top: 20px;
    justify-content: space-between;
  }
  .inputElement {
    margin-bottom: 20px;
    max-width: 416px;
  }
  .headerInputs input {
    max-width: 416px;
  }
  .menuBar {
    display: block;
  }
  .menuClose.closeActiv {
    display: block;
    position: absolute;
    top: 20px;
    right: 30px;
  }
  .menuClose {
    display: none;
  }
}
