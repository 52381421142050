.whatIsLed {
  position: relative;
  margin-top: 80px;
  padding-bottom: 80px;
}
.whatIsLed::before {
  content: "";
  position: absolute;
  background-image: url("../../images/whatisledvector.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  z-index: 1;
}
.whatIsLed::after {
  content: "";
  position: absolute;
  background-image: url("../../images/whatisledvector2.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 150px;
  z-index: 1;
  background-position: right;
}
.whatIsLedContentOne,
.whatIsLedContentTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
}
.whatIsLedContentOne .whatIsLedImag {
  position: relative;
  max-width: 350px;
  width: 100%;
  height: 392px;
  left: 100px;
}
.whatIsLedContentTwo {
  margin-top: 50px;
}
.whatIsLedContentTwo .whatIsLedImag {
  position: relative;
  max-width: 350px;
  width: 100%;
  height: 392px;
}
.whatIsLedContentOne .whatIsLedImag img,
.whatIsLedContentTwo .whatIsLedImag img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.whatIsLedContentOne .whatIsLedImag .whatIsLedFirst {
  width: 281px;
  left: 81px;
  z-index: 2;
  transition: all 0.5s;
}
.whatIsLedContentOne .whatIsLedImag .whatIsLedFirst:hover {
  transform: scale(1.1);
  box-shadow: 1px 1px 5px rgba(219, 79, 219, 0.3),
    -1px -1px 5px rgba(255, 255, 0, 0.3), 1px 1px 8px rgba(131, 240, 240, 0.3),
    -1px -1px 8px rgba(255, 120, 255, 0.3);
}

.whatIsLedContentTwo .whatIsLedImag .whatIsLedFirst {
  width: 281px;
  left: 0px;
  z-index: 2;
  transition: all 0.5s;
}
.whatIsLedContentTwo .whatIsLedImag .whatIsLedFirst:hover {
  transform: scale(1.1);
  box-shadow: 1px 1px 5px rgba(219, 79, 219, 0.3),
    -1px -1px 5px rgba(255, 255, 0, 0.3), 1px 1px 8px rgba(131, 240, 240, 0.3),
    -1px -1px 8px rgba(255, 120, 255, 0.3);
}
.whatIsLedContentOne .whatIsLedImag .whatIsLedSecond {
  left: 0px;
  bottom: 0;
  z-index: 1;
}

.whatIsLedContentTwo .whatIsLedImag .whatIsLedSecond {
  right: 80px;
  bottom: 0;
  z-index: 1;
}
.whatIsLedContentOne .whatIsLedText,
.whatIsLedContentTwo .whatIsLedText {
  max-width: 600px;
  padding: 10px 15px;
}
.whatIsLedContentOne .whatIsLedText h3,
.whatIsLedContentTwo .whatIsLedText h3 {
  font-family: travelBold;
  font-size: 48px;
  line-height: 67px;
  font-weight: 800;
}
.whatIsLedContentOne .whatIsLedText p,
.whatIsLedContentTwo .whatIsLedText p {
  font-family: commision;
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  margin-top: 20px;
}
@media (max-width: 1100px) {
  .whatIsLed {
    margin-top: 50px;
  }
  .whatIsLedContentOne .whatIsLedImag {
    left: 10px;
  }
}
@media (max-width: 992px) {
  .whatIsLedContentOne .whatIsLedText,
  .whatIsLedContentTwo .whatIsLedText {
    max-width: 500px;
  }
}
@media (max-width: 900px) {
  .whatIsLedContentOne .whatIsLedText,
  .whatIsLedContentTwo .whatIsLedText {
    max-width: 400px;
  }
  .whatIsLedContentOne .whatIsLedText h3,
  .whatIsLedContentTwo .whatIsLedText h3 {
    font-size: 35px;
    line-height: 40px;
  }
  .whatIsLedContentOne .whatIsLedText p,
  .whatIsLedContentTwo .whatIsLedText p {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 800px) {
  .whatIsLedContentOne .whatIsLedText,
  .whatIsLedContentTwo .whatIsLedText {
    max-width: 300px;
  }
}
@media (max-width: 700px) {
  .whatIsLedContentOne .whatIsLedText,
  .whatIsLedContentTwo .whatIsLedText {
    max-width: 600px;
    text-align: center;
    margin-bottom: 15px;
  }
  .whatIsLed {
    margin-top: 0px;
  }
  .whatIsLedContentOne {
    flex-direction: column-reverse;
  }
  .whatIsLedContentTwo {
    flex-direction: column;
  }
  .whatIsLedContentOne .whatIsLedImag {
    left: 0;
  }
  .whatIsLedContentTwo .whatIsLedImag,
  .whatIsLedContentOne .whatIsLedImag {
    width: 194px;
    height: 270px;
  }
  .whatIsLedContentOne .whatIsLedImag .whatIsLedFirst {
    width: 194px;
  }
  .whatIsLedContentOne .whatIsLedImag .whatIsLedFirst {
    left: 0;
  }
  .whatIsLedContentOne .whatIsLedImag .whatIsLedSecond,
  .whatIsLedContentTwo .whatIsLedImag .whatIsLedSecond {
    left: unset;
    right: 50px;
  }
  .whatIsLedContentOne .whatIsLedText h3,
  .whatIsLedContentTwo .whatIsLedText h3 {
    font-size: 24px;
    line-height: 33px;
  }
  .whatIsLedContentOne .whatIsLedText p,
  .whatIsLedContentTwo .whatIsLedText p {
    font-size: 16px;
    line-height: 24px;
  }
  .whatIsLedContentTwo .whatIsLedImag .whatIsLedFirst {
    width: 194px;
  }
  .whatIsLed::after {
    background-image: url("../../images/whatisledafter.png");
    background-position: center right;

    top: 320px;
  }
  .whatIsLed::before {
    background-image: url("../../images/advantigemobile.png");
  }
}
