.secondHeader {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.secondHeader .logo {
  width: 220px;
  height: 63px;
}
.secondHeader .secondHeaderUser {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.secondHeader .secondHeaderUser p {
  font-size: 18px;
  font-family: commision;
  font-weight: 500;
}
.secondHeader .secondHeaderUser .userName {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: linear-gradient(#df4ede, #c95beb);
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
}
.comentSettings{
    display: none;
}
.secondHeader .secondHeaderUser div .headerUser {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.settings {
  position: absolute;
  top: 100%;
  right: 0;
  background: rgba(0, 0, 0, 0.85);
  border-width: 0.95px;
  border-style: solid;
  border-left-color: #6f378b;
  border-top-color: #6f378b;
  border-right-color: #c060f1;
  border-bottom-color: #c060f1;
  border-radius: 10px;
  margin-top: 15px;
  z-index: 10;
}
.settings::after {
  content: "";
  background-image: url("../../images/up.png");
  width: 14px;
  height: 8px;
  position: absolute;
  top: -9px;
  right: 10px;
  display: block;
}
.settings a, .settings .logout {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 5px 10px;
  text-decoration: none;
  color: #fff;
}
.settings .logout {
  padding: 3px 10px 10px;
  cursor: pointer;
}
.settings p {
  font-size: 16px;
  font-family: commision;
  line-height: 27px;
}
.settings .headersettings {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
@media(max-width: 576px){
  .secondHeader .logo{
    width: 140px;
    height: 40px;
  }
  .secondHeader .secondHeaderUser p {
    font-size: 14px;
    text-align: center;
   
  }
  .userPTag{
    word-break: break-word;
  }
  .secondHeader .secondHeaderUser .userName{
    width: 38px;
    height: 38px;
  }
  .secondHeader .secondHeaderUser div .headerUser{
    width: 19px;
    height: 19px;
  }
}
